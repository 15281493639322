import { gql } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useMemo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import {
  MeasurementType,
  FulfilmentType,
  OrderPageQuery,
  GarmentCategory,
  useOrderItemMeasurementCellMeasurementsQuery,
  useOrderItemMeasurementCellSetMeasurementMutation,
  ManufacturerGarmentOfferingsQuery,
} from '@graphql';
import { Icon, Tooltip } from 'components';
import { MeasurementTypeObject, MeasurementTypePill, MeasurementsSelect, canUseFinishedMeasurement } from 'modules/measurements';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { ArrayElement } from 'types/common';
import { hasParentGarmentCategory, hasChildGarmentCategory } from '../orderItem.helpers';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
interface Props {
  className?: string;
  order: OrderPageQuery['order'];
  orderItem: ArrayElement<OrderPageQuery['order']['items']>;
  locked?: boolean;
  designId: string;
  mfrGarmentOfferings: ManufacturerGarmentOfferingsQuery['manufacturerGarmentOfferings'];
}

const getMeasurementLink = ({
  measurementId,
  customerId,
  garmentCategory,
  designId,
  orderId,
}: {
  measurementId: string;
  customerId: string;
  garmentCategory: GarmentCategory;
  designId: string;
  orderId: string;
}) => {
  const actionMap = {
    ADD_BODY: MeasurementType.Body,
    ADD_GARMENT: MeasurementType.Garment,
    ADD_SIMPLIFIED: MeasurementType.Simplified,
    ADD_TRY_ON: MeasurementType.TryOn,
    ADD_APPLIED: MeasurementType.Applied,
  };

  const params = new URLSearchParams({
    type: actionMap[measurementId],
    customerId,
    garmentCategory,
    designId,
    orderId,
  }).toString();

  return `/measurements/new?${params}`;
};

export const OrderItemMeasurementCell = ({ className = '', order, orderItem, locked = false, designId, mfrGarmentOfferings }: Props) => {
  const router = useRouter();
  const { fulfilment } = useActiveSession();
  const {
    convertFromServerUnit,
    measurementUnitInfo: { label: unitLabel },
  } = useMeasurementUnit();

  const { data: measurementData } = useOrderItemMeasurementCellMeasurementsQuery({
    skip: !order?.customer?.id,
    variables: { customerId: order?.customer?.id },
    fetchPolicy: 'network-only',
  });
  const design = useMemo(() => orderItem?.designs.find((d) => d.id === designId), [orderItem]);
  const measurements = useMemo(
    () =>
      measurementData?.measurements
        .filter((m) => {
          switch (fulfilment) {
            case FulfilmentType.Pdf:
              //return [MeasurementType.Garment, MeasurementType.Simplified, MeasurementType.Applied].includes(m.type.key);
              return [];
            case FulfilmentType.JeromeFinished:
              //return [MeasurementType.Garment, MeasurementType.Simplified, MeasurementType.Applied].includes(m.type.key);
              return [];
            case FulfilmentType.JeromeSimplified:
              //return [MeasurementType.Simplified].includes(m.type.key);
              return [];
            case FulfilmentType.Jerome:
              //return [MeasurementType.Garment, MeasurementType.TryOn, MeasurementType.Simplified, MeasurementType.Applied].includes(m.type.key);
              return [];
            case FulfilmentType.JeromeTryOn:
              //return m.type.key === MeasurementType.TryOn;
              return [];
            default:
              false;
          }
        })
        .filter(
          (m) =>
            (m.type.key === MeasurementType.Body && canUseFinishedMeasurement(design?.garmentCategory.key)) ||
            m.garmentCategory?.key === orderItem?.designs.find((design) => design.id === designId)?.garmentCategory?.key ||
            hasParentGarmentCategory({
              mfrGarmentOfferings,
              currentGarmentCategory: m.garmentCategory?.key,
              orderItemGarmentCategory: orderItem?.designs?.find((design) => design.id === designId)?.garmentCategory?.key,
            }) ||
            hasChildGarmentCategory({
              mfrGarmentOfferings,
              currentGarmentCategory: m.garmentCategory?.key,
              orderItemGarmentCategory: orderItem?.designs?.find((design) => design.id === designId)?.garmentCategory?.key,
            })
        )
        .sort((a, b) => a.type.key.localeCompare(b.type.key)),
    [measurementData, fulfilment, design]
  );

  const [setOrderItemDesignMeasurement, { loading: isLoading }] = useOrderItemMeasurementCellSetMeasurementMutation();
  const savedMeasurement = design?.measurement;

  const classes = cn(className, 'flex items-center');

  const saveMeasurement = async (measurementId: string) => {
    try {
      await setOrderItemDesignMeasurement({
        variables: {
          orderId: order.id,
          orderItemDesignId: designId,
          orderItemMeasurementInput: { measurementId },
        },
      });
      toast.success('Measurement set');
    } catch (e) {
      console.error(e);
      toast.error(`${e?.message ?? 'Whoops, something went wrong.'}`, { autoClose: false });
    }
  };

  const handleChange = async (measurementId: string) => {
    const customerId = order?.customer?.id;
    const garmentCategory = design?.garmentCategory?.key;
    switch (measurementId) {
      case 'ADD_BODY':
      case 'ADD_GARMENT':
      case 'ADD_SIMPLIFIED':
      case 'ADD_TRY_ON':
      case 'ADD_APPLIED': {
        router.push(getMeasurementLink({ measurementId, customerId, garmentCategory, designId, orderId: order.id }));
        break;
      }
      default: {
        await saveMeasurement(measurementId);
        break;
      }
    }
  };

  // if `designId` and `measurementId` are present in query params
  // assume user has been redirect from measurements to apply measurement
  useEffect(() => {
    if (isLoading || !designId) return;

    const { query } = router;

    if (designId === query?.designId && query?.measurementId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { designId, measurementId, ...rest } = query;

      // strip out query params so hook doesn't re-fire if navigating back etc.
      router.push({ query: { ...rest } }, undefined, { shallow: true });
      handleChange(measurementId as string);
    }
  }, [designId, router]);

  if (!measurements || !designId) {
    // render empty placeholder if measurements haven't been loaded
    return <div className={className} />;
  }
  const hasBodyMeasurement = measurements?.some((m) => m?.type?.key === MeasurementType.Body);

  return (
    <>
      <div className={classes}>
        {locked ? (
          <div
            className="inline-flex items-center bg-emerald-50 text-emerald-500 py-1 px-2 rounded text-xs whitespace-nowrap mr-2 hover:bg-emerald-100 hover:text-emerald-600"
            data-tip={savedMeasurement?.name}
          >
            <CheckCircleIcon className="h-4 w-4 mr-1" />
            Measurement
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
        ) : (
          <>
            <div className="w-72">
              <MeasurementsSelect
                remake={orderItem.remake}
                isDisabled={isLoading}
                options={measurements
                  ?.filter((x) => x.type.key != MeasurementType.Body)
                  .map((m) => {
                    return { value: m.id, label: m.name, type: m.type as MeasurementTypeObject };
                  })}
                value={savedMeasurement?.createdFrom?.id}
                onChange={({ value }) => handleChange(value as string)}
                garmentCategory={design?.garmentCategory.key}
                hasBodyMeasurement={hasBodyMeasurement}
              />
            </div>
            {savedMeasurement?.createdFrom?.id &&
              [MeasurementType.Body, MeasurementType.Garment, MeasurementType.Simplified, MeasurementType.Applied].includes(
                savedMeasurement?.type?.key
              ) && (
                <div className="flex mx-3">
                  <Tooltip
                    variant="dark"
                    content={
                      <div className="w-96 text-neutral-50 p-4">
                        <div className="border-b border-neutral-800 text-left pb-4">
                          <div className="flex justify-between items-center flex-wrap font-semibold">
                            <div className="flex">
                              <div className="text-yellow-crayola-500 mr-4">
                                {order?.customer.firstName} {order?.customer.lastName}
                              </div>
                              {savedMeasurement.createdFrom?.createdAt.formatted}
                            </div>
                            <MeasurementTypePill typeName={savedMeasurement.type.key} />
                          </div>
                          <div className="mt-2 flex justify-between">
                            <div className="font-semibold">Finished measurement</div>
                            {(savedMeasurement?.type?.key === MeasurementType.Body || savedMeasurement?.type?.key === MeasurementType.Applied) && (
                              <div>
                                <span className="mr-1 font-semibold">Fit:</span>
                                {savedMeasurement?.fit.name}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-4 text-left">
                          {savedMeasurement?.finishedMeasurementFields.map((field) => (
                            <div key={`${field.key}${field.label}`}>
                              {field.label}: {convertFromServerUnit(field.value, 1)}
                              {unitLabel}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <Icon icon="info-stroke" height={18} width={18} />
                  </Tooltip>
                </div>
              )}
            {savedMeasurement?.createdFrom?.id && savedMeasurement?.type?.key === MeasurementType.TryOn ? (
              <div className="flex mx-3">
                <Tooltip
                  variant="dark"
                  content={
                    <div className="w-96 text-neutral-50 p-4">
                      <div className="border-b border-neutral-800 text-left pb-4">
                        <div className="flex justify-between items-center font-semibold">
                          <div className="flex">
                            <div className="text-yellow-crayola-500 mr-4">
                              {order?.customer.firstName} {order?.customer.lastName}
                            </div>
                            {savedMeasurement.createdFrom?.createdAt.formatted}
                          </div>
                          <MeasurementTypePill typeName={savedMeasurement.type.key} />
                        </div>

                        <div className="mt-2">Size: {savedMeasurement?.size}</div>
                      </div>
                      <ul className="mt-4">
                        {savedMeasurement?.tweaks.map((tweak, count) => {
                          if (tweak.__typename === 'TryOnMeasurementTweak') {
                            return (
                              <li key={`measurement.tweaks${count}`} className="my-1 text-left">{`${tweak?.typeName}: ${
                                tweak?.itemName
                              } ${convertFromServerUnit(Number(tweak?.option))}${unitLabel}`}</li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  }
                >
                  <Icon icon="info-stroke" height={18} width={18} />
                </Tooltip>
              </div>
            ) : (
              <div className="flex mx-3">&nbsp;</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const OrderItemMeasurementCellTooltip = {
  fragments: {
    root: gql`
      fragment OrderItemMeasurementCellItem on OrderItem {
        id
        itemStatus {
          key
        }
        designs {
          id
          garmentCategory {
            key
          }
          measurement {
            id
            name
            size
            fit {
              name
              key
            }
            type {
              key
              name
            }
            finishedMeasurementFields {
              key
              value
              label
            }
            tweaks {
              ... on GarmentMeasurementTweak {
                __typename
              }
              ... on TryOnMeasurementTweak {
                __typename
                typeName
                itemName
                option
              }
            }
            createdFrom {
              id
              name
              createdAt {
                formatted(format: "ll")
              }
            }
          }
        }
      }
    `,
  },
};

OrderItemMeasurementCell.fragments = {
  root: gql`
    ${OrderItemMeasurementCellTooltip.fragments.root}

    fragment OrderItemMeasurementCell on Order {
      id
      customer {
        id
        firstName
        lastName
      }
      items {
        ...OrderItemMeasurementCellItem
      }
    }
  `,
  measurementsList: gql`
    fragment OrderItemMeasurementCellMeasurements on Measurement {
      id
      name
      type {
        name
        key
      }
      updatedAt {
        fromNow
        origin
      }
      garmentCategory {
        key
        name
      }
    }
  `,
};

OrderItemMeasurementCell.queries = {
  measurement: gql`
    ${OrderItemMeasurementCell.fragments.measurementsList}

    query OrderItemMeasurementCellMeasurements($customerId: ID!) {
      measurements(customerId: $customerId) {
        ...OrderItemMeasurementCellMeasurements
      }
    }
  `,
};

OrderItemMeasurementCell.mutation = gql`
  ${OrderItemMeasurementCellTooltip.fragments.root}

  mutation OrderItemMeasurementCellSetMeasurement($orderId: ID!, $orderItemMeasurementInput: OrderItemMeasurementInput!, $orderItemDesignId: ID!) {
    setOrderItemDesignMeasurement(
      orderId: $orderId
      orderItemDesignId: $orderItemDesignId
      orderItemDesignMeasurementInput: $orderItemMeasurementInput
    ) {
      id
      orderItem {
        order {
          id
          outstandingRequirements {
            status
            requirements
          }
        }
        ...OrderItemMeasurementCellItem
      }
    }
  }
`;
