import { MeasurementFormHeader } from 'modules/measurements/components/MeasurementFormHeader';
import { BodyMeasurementForm } from 'modules/measurements/components/BodyMeasurementForm';
import { GarmentMeasurementForm } from 'modules/measurements/components/GarmentMeasurementForm';
import { SimplifiedMeasurementForm } from 'modules/measurements/components/SimplifiedMeasurementForm';
import { TryOnMeasurementForm } from 'modules/measurements/components/TryOnMeasurementForm';
import { AppliedMeasurementForm } from 'modules/measurements/components/AppliedMeasurementForm';
import { GarmentCategory, MeasurementType } from '@graphql';

interface Props {
  customerId: string;
  type: MeasurementType;
  measurementId?: string;
}

export interface FormProps {
  customerId: string;
  garmentCategory?: GarmentCategory;
  measurementId?: string;
  hideGarmentCategory?: boolean;
}

const FormMap: Record<MeasurementType, React.JSXElementConstructor<FormProps>> = {
  [MeasurementType.Body]: BodyMeasurementForm,
  [MeasurementType.Garment]: GarmentMeasurementForm,
  [MeasurementType.Simplified]: SimplifiedMeasurementForm,
  [MeasurementType.TryOn]: TryOnMeasurementForm,
  [MeasurementType.Applied]: AppliedMeasurementForm,
};

const GarmentCategoryFilterMap: Record<MeasurementType, 'garmentMeasurement' | undefined> = {
  [MeasurementType.Body]: undefined,
  [MeasurementType.Garment]: 'garmentMeasurement',
  [MeasurementType.Simplified]: 'garmentMeasurement',
  [MeasurementType.TryOn]: undefined,
  [MeasurementType.Applied]: 'garmentMeasurement',
};

const MeasurementFormWrap = ({ type, measurementId, customerId }: Props): React.ReactElement => {
  const Form = FormMap[type];

  return (
    <MeasurementFormHeader
      measurementId={measurementId}
      hideGarmentCategory={type === MeasurementType.Body}
      measurementType={type}
      garmentCategoryFilter={GarmentCategoryFilterMap[type]}
    >
      {({ garmentCategory }) => <Form garmentCategory={garmentCategory} measurementId={measurementId} customerId={customerId} />}
    </MeasurementFormHeader>
  );
};

export { MeasurementFormWrap };
