import { CSSProperties, ComponentProps, Fragment, useMemo, useState } from 'react';
import cn from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { Fit, GetTryOnMeasurementQueryResult, MeasurementUnit } from '@graphql';
import { Icon, Select, SlideoverPanel, ToggleSelect } from 'components';
import { getUnitLongName, parseMeasurementLabel, validateNumber } from 'helpers/measurement-helpers';
import { MeasurementFormBlockDisplay, MeasurementFormBlockDisplayHeading } from 'modules/measurements/components/MeasurementFormBlockDisplay';
import { ArrayElement } from 'types/common';
import { Button } from 'modules/common/components';
import { TryOnTweakInfo } from './TryOnTweakInfo';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';

export type Tweak = ArrayElement<GetTryOnMeasurementQueryResult['data']['settings']['measurement']['tryOn']['tweaks']>;
export type DefaultTweak = ArrayElement<GetTryOnMeasurementQueryResult['data']['settings']['measurement']['tryOn']['defaults']['tweaks']>;
export type Item = ArrayElement<Tweak['items']>;
export type Influence = ArrayElement<GetTryOnMeasurementQueryResult['data']['influences']>;

interface Props {
  tweaks: Tweak[];
  defaultTweaks: DefaultTweak[];
  fit: Fit;
  size: string;
  influences: Influence[];
  displayGroup: string;
  measurementUnitInfo: ReturnType<typeof useMeasurementUnit>['measurementUnitInfo'];
  convertFromServerUnit: ReturnType<typeof useMeasurementUnit>['convertFromServerUnit'];
  updateMeasurementUnit: ReturnType<typeof useMeasurementUnit>['updateMeasurementUnit'];
}

type TweakKeyInfoProps = ComponentProps<typeof TryOnTweakInfo>;

const getBlockMeasurement = (tweak: Tweak, fit: Fit, size: string) =>
  tweak.blockMeasurements?.find((measurement) => measurement.fit === fit && measurement.blockMeasurement === size);

export const Tweaks = (props: Props): React.ReactElement => {
  const { tweaks, defaultTweaks, fit, size, influences, displayGroup, measurementUnitInfo, convertFromServerUnit, updateMeasurementUnit } = props;
  const hasDefaults = !!defaultTweaks?.length;

  const { register, watch, control } = useFormContext();

  const [tweakInfoProps, setTweakInfoProps] = useState<TweakKeyInfoProps>(null);

  const getSelectedTweakValue = ({ items }: Tweak, index: number) => items.find(({ key }) => key === watch(`tweaks.${index}.itemKey` as const));

  const getInfluencedValue = ({ name }: Tweak) => {
    const influencedParts = influences.filter(({ influencedPart }) => influencedPart === name);

    return influencedParts.reduce((prev, { bodyPart, influence }) => {
      const index = tweaks.findIndex(({ name }) => name === bodyPart);
      const value = watch(`tweaks.${index}.option`) || 0;

      return prev + value * (influence / 100);
    }, 0);
  };

  const nextMeasurementUnit = measurementUnitInfo.current === MeasurementUnit.Cm ? MeasurementUnit.Inch : MeasurementUnit.Cm;

  const gridStyles = useMemo(
    (): CSSProperties => ({
      gridTemplateColumns: hasDefaults ? '1fr 1fr 1fr 140px 65px 65px 65px' : '1fr 1fr 1fr 140px 65px 65px',
    }),
    [hasDefaults]
  );

  return (
    <div data-testid="measurement-tweaks">
      <div className="flex w-full justify-between items-center mb-4">
        <Button
          className="ml-auto"
          size="xs"
          variant="neutral"
          onClick={() => updateMeasurementUnit(nextMeasurementUnit)}
        >{`Convert to ${getUnitLongName(nextMeasurementUnit)}`}</Button>
      </div>
      <div className="grid gap-4" style={gridStyles}>
        <div className="col-span-4"></div>
        {hasDefaults && <MeasurementFormBlockDisplayHeading heading="Default" className="col-span-1" />}
        <MeasurementFormBlockDisplayHeading heading="Block" />
        <MeasurementFormBlockDisplayHeading color="text-gray-700" heading="Finished" />
      </div>
      <div className="flex flex-col gap-4">
        {tweaks.map((tweak, index) => {
          const selectedTweak = getSelectedTweakValue(tweak, index);
          const incrementValue = watch(`tweaks.${index}.option`) || 0;
          const blockMeasurement = getBlockMeasurement(tweak, fit, size);
          const defaultMeasurement = defaultTweaks?.find((d) => d?.['typeKey'] === tweak.key);
          const influencedValue = getInfluencedValue(tweak);
          const blockValue = Number(blockMeasurement?.value);
          const defaultValue = Number(defaultMeasurement?.['option']);
          const valueToAdd = influencedValue || Number(incrementValue);
          if (tweak.displayGroup !== displayGroup) return null;

          return (
            <div key={index} className="grid gap-4" style={gridStyles}>
              <div className="col-span-1 flex items-center">
                <div className="text-sm text-gray-500">{parseMeasurementLabel(tweak.name)}</div>
              </div>
              <div className="col-span-2">
                <Controller
                  name={`tweaks.${index}.itemKey`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <ToggleSelect
                        testId={tweak.name}
                        options={tweak.items.map((item) => ({ value: item.key, label: item.name }))}
                        onChange={onChange}
                        value={value || watch(`tweaks.${index}.itemKey`)}
                        isDisabled={!!Number(incrementValue)}
                      />
                    );
                  }}
                />
              </div>
              <div className="col-span-1 flex">
                <Select
                  testId={`${tweak.name} increment`}
                  register={register(`tweaks.${index}.option` as const)}
                  htmlProps={{ value: incrementValue, disabled: !selectedTweak }}
                >
                  {selectedTweak?.options.map((option) => (
                    <option key={option.key} value={option.key}>
                      {validateNumber(option.name) ? `${convertFromServerUnit(Number(option.name))} ${measurementUnitInfo.label}` : option.name}
                    </option>
                  ))}
                </Select>
                <div>
                  {selectedTweak?.info ? (
                    <button
                      type="button"
                      className="self-center ml-5"
                      disabled={!selectedTweak}
                      onClick={() => {
                        setTweakInfoProps({ info: selectedTweak.info, title: tweak.name, subtitle: selectedTweak.name });
                      }}
                    >
                      <Icon icon="info-stroke" width={12} height={12} />
                    </button>
                  ) : (
                    <div className="w-8" />
                  )}
                </div>
              </div>
              {hasDefaults && (
                <MeasurementFormBlockDisplay
                  className="col-span-1"
                  testId={`${tweak.name} default`}
                  showValue={!!defaultMeasurement}
                  value={convertFromServerUnit(defaultValue)}
                  measurementUnitLabel={measurementUnitInfo.label}
                />
              )}
              <MeasurementFormBlockDisplay
                testId={`${tweak.name} block`}
                showValue={!!blockMeasurement}
                value={convertFromServerUnit(blockValue)}
                measurementUnitLabel={measurementUnitInfo.label}
              />
              <MeasurementFormBlockDisplay
                testId={`${tweak.name} finished`}
                showValue={!!blockMeasurement}
                value={convertFromServerUnit(blockValue + valueToAdd)}
                measurementUnitLabel={measurementUnitInfo.label}
                color="text-gray-700"
              />
            </div>
          );
        })}
      </div>
      <SlideoverPanel
        isOpen={!!tweakInfoProps}
        setIsOpen={(isOpen) => !isOpen && setTweakInfoProps(null)}
        maxWidthCss="max-w-xl"
        showCancel={false}
        showExit={false}
        submitButton={<Button onClick={() => setTweakInfoProps(null)}>Close</Button>}
      >
        {tweakInfoProps && <TryOnTweakInfo {...tweakInfoProps} />}
      </SlideoverPanel>
    </div>
  );
};
