import { Fragment } from 'react';
import { gql } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';

import { AppliedMeasurementInput, GetAppliedMeasurementQueryResult, GetBodyMeasurementQueryResult } from '@graphql';
import { Input, UnitInput } from 'components';
import { MeasurementFormBlockDisplay, MeasurementFormBlockDisplayHeading } from 'modules/measurements/components/MeasurementFormBlockDisplay';
import { useMeasurementUnit } from 'modules/common/MeasurementUnit';
import { roundToDp, validateNumber } from 'helpers/measurement-helpers';

interface Props {
  fields: AppliedMeasurementFields;
  bodyFields: BodyMeasurementFields;
  measurementUnitUtils: ReturnType<typeof useMeasurementUnit>;
}

type AppliedMeasurementFields = GetAppliedMeasurementQueryResult['data']['settings']['measurement']['garment']['fields'];
type BodyMeasurementFields = GetBodyMeasurementQueryResult['data']['measurement']['fields'];

export const AppliedMeasurements = ({ fields, bodyFields, measurementUnitUtils }: Props) => {
  const { register, watch, control } = useFormContext<AppliedMeasurementInput>();
  const { convertFromServerUnit, measurementUnitInfo } = measurementUnitUtils;

  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-3"></div>
        <MeasurementFormBlockDisplayHeading heading="Body" />
        <MeasurementFormBlockDisplayHeading heading="Allowance" />
        <MeasurementFormBlockDisplayHeading color="text-gray-700" heading="Finished" />
      </div>
      <div data-testid="applied-measurements" className="grid grid-cols-6 gap-4">
        {fields.map((field, index) => {
          const { isHalved, range } = field;
          const allowance = watch(`tweaks.${index}.value`) || 0;
          const bodyMeasurement = bodyFields?.find((m) => m.key === field?.bodyMeasurementKey);
          const bodyMeasurementValue = bodyMeasurement?.value && convertFromServerUnit(bodyMeasurement?.value);
          const minValue = roundToDp(Math.ceil(convertFromServerUnit(range.min) * (isHalved ? 2 : 1)) - bodyMeasurementValue, 2);
          const maxValue = roundToDp(Math.floor(convertFromServerUnit(range.max) * (isHalved ? 2 : 1)) - bodyMeasurementValue, 2);
          const finished = validateNumber(allowance, null) ? roundToDp(bodyMeasurementValue + Number(allowance), 2) : 0;

          return (
            <Fragment key={field.key}>
              <div className="col-span-3 flex items-center">
                <div className="text-sm text-gray-500">{isHalved ? field.name.replace('Half', '') : field.name}</div>
              </div>
              <div className="hidden">
                <Input register={register(`tweaks.${index}.key`, { value: field.key })} htmlProps={{ hidden: true }} />
              </div>
              <MeasurementFormBlockDisplay showValue value={bodyMeasurementValue} />
              <div className="col-span-1">
                <Controller
                  name={`tweaks.${index}.value` as const}
                  control={control}
                  rules={{
                    required: `Enter a ${measurementUnitInfo.shortName} value (2dp max)`,
                    validate: (v) => validateNumber(v) || `Value must be a number (2dp max)`,
                    min: minValue,
                    max: maxValue,
                  }}
                  render={({ field: formField, fieldState }) => (
                    <UnitInput
                      unit={measurementUnitInfo.label}
                      htmlProps={{
                        id: field.name,
                        ...formField,
                        onChange: (e) => {
                          const rawUnitValue = e.target.value;
                          if (rawUnitValue === '' || validateNumber(rawUnitValue, 2)) {
                            formField.onChange(e);
                          }
                        },
                      }}
                      errorMessage={(() => {
                        switch (fieldState.error?.type) {
                          case 'required':
                            return `Enter a ${measurementUnitInfo.shortName} value (2dp max)`;
                          case 'max':
                            return `Max ${maxValue} ${measurementUnitInfo.shortName} Exceeded`;
                          case 'min':
                            return `Min ${minValue} ${measurementUnitInfo.shortName} Exceeded`;
                          default:
                            return '';
                        }
                      })()}
                    />
                  )}
                />
              </div>
              <MeasurementFormBlockDisplay showValue value={finished} color="text-gray-700" />
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

AppliedMeasurements.fragments = {
  AppliedMeasurementFragment: gql`
    fragment AppliedMeasurementFragment on Measurement {
      id
      name
      fit {
        key
        name
      }
      fields {
        key
        value
      }
      tweaks {
        ... on GarmentMeasurementTweak {
          key
          value
        }
      }
    }
  `,
};

AppliedMeasurements.query = gql`
  query GetAppliedMeasurement($garmentCategory: GarmentCategory!, $measurementId: ID, $customerId: ID!) {
    measurement(measurementId: $measurementId) {
      ...AppliedMeasurementFragment
    }
    measurements(customerId: $customerId) {
      id
      name
      type {
        name
        key
      }
      fields {
        key
        name
        value
      }
      updatedAt {
        fromNow
        origin
      }
      garmentCategory {
        key
        name
      }
    }
    settings {
      id
      measurement {
        garment(garmentCategory: $garmentCategory) {
          fields {
            key
            bodyMeasurementKey
            name
            isHalved
            isRequired
            range {
              min
              max
            }
            allowances {
              COMFORTABLE
              REGULAR
              SLIM
            }
          }
          fits {
            key
            name
          }
        }
      }
    }
  }
  ${AppliedMeasurements.fragments.AppliedMeasurementFragment}
`;

AppliedMeasurements.mutations = {
  UpsertAppliedMeasurement: gql`
    mutation UpsertAppliedMeasurement($customerId: ID, $appliedMeasurementInput: AppliedMeasurementInput!) {
      upsertAppliedMeasurement(customerId: $customerId, appliedMeasurementInput: $appliedMeasurementInput) {
        ...AppliedMeasurementFragment
      }
    }
    ${AppliedMeasurements.fragments.AppliedMeasurementFragment}
  `,
};
