import React from 'react';
import { IndividualPricingState } from './IndividualPricingState';
import { ClipboardListIcon } from '@heroicons/react/outline';

type Props = {
  headers: IndividualPricingState['headers'];
};

export const ExampleCsvButton = ({ headers }: Props) => {
  return (
    <a href={generateExamplePriceCsv(headers)} target="_blank" download="fabric_import_example.csv" rel="noreferrer">
      <div className={'flex items-center text-xs text-gray-500'}>
        <ClipboardListIcon className="h-6 w-6 mr-2" />
        CSV Template
      </div>
    </a>
  );
};

const generateExamplePriceCsv = (headers: IndividualPricingState['headers']) => {
  const th = ['fabric code', 'product category', 'amount'];

  const td = headers.map(({ label }, index) => ['EXAMPLE_CODE', label, Number((index + 1) * 10).toString()]);

  return `data:text/csv;charset=utf-8,${[th, ...td].map((cell) => cell.map((c) => `"${c}"`).join(',')).join('\n')}`;
};
