import { useState } from 'react';
import { GetProductPricingQuery, GetLiningPricingQuery, ProductCategory } from '@graphql';
import { Spinner } from 'components/Spinner';
import { Button } from 'modules/common';
import { PricingTable } from 'modules/pricing/components/PricingTable';
import { OnPriceClick, PriceType } from 'modules/pricing/pricing.types';
import { MarginModal } from 'modules/pricing/components/MarginModal';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { formatAsPercent } from 'helpers/reporting-helpers';

interface Props {
  type: PriceType;
  data: GetProductPricingQuery & GetLiningPricingQuery;
  isLoading: boolean;
  isCutLength?: boolean;
  onPriceClick: OnPriceClick;
  onSetupClick: (args: { variables: { productCategories: ProductCategory[] } }) => void;
}

export const PricingTab = ({ type, data, isLoading, isCutLength, onPriceClick, onSetupClick }: Props) => {
  const { tailor } = useActiveSession();
  const defaultMarginPercent = formatAsPercent(tailor.defaultMarginPercent);
  const [isOpen, setIsOpen] = useState(false);

  const productCategories = data?.settings?.productCategories.map((p) => p.key);

  if (isLoading)
    return (
      <div className="absolute inset-0 m-auto flex items-center justify-center">
        <Spinner />
      </div>
    );

  if (!data?.tiers?.length && !isCutLength) {
    return (
      <div>
        <p className="mb-4">{`No ${type.toLowerCase()} prices have been set.`}</p>
        <Button onClick={() => onSetupClick({ variables: { productCategories } })}>{`Setup ${type} Pricing`}</Button>
      </div>
    );
  }

  return (
    <>
      {type === 'Fabric' && isCutLength && (
        <>
          <div className="text-sm ml-3">
            <p className="my-4">
              The Retail Price (RRP) for <em className="font-semibold">Cut Length Fabrics</em> are automatically generated when applied to a garment
              and will appear in the pricing matrix below.
            </p>
            <p className="my-4">
              RRP is calculated based on the{' '}
              <em>
                estimated cost price<sup>1</sup>
              </em>{' '}
              with a{' '}
              <em>
                default margin<sup>2</sup>
              </em>{' '}
              applied. Prices can be manually updated within the pricing matrix if required.
            </p>
            <div className="flex items-center mb-8">
              <p className="mr-2">
                Current Margin: <span className="font-semibold">{defaultMarginPercent}</span>
              </p>
              <Button size="xs" onClick={() => setIsOpen(true)}>
                Update Margin
              </Button>
            </div>
            <ul className="text-2xs">
              <li>
                <sup className="mr-0.5">1</sup>
                {`Estimated cost price = CMT, fabric and shipping cost converted to the Tailor's local currency.`}
              </li>
              <li>
                <sup className="mr-0.5">2</sup>
                {`If no margin has been set a default of 70% is applied.`}
              </li>
            </ul>
          </div>
          <MarginModal isOpen={isOpen} setIsOpen={setIsOpen} defaultMarginPercent={defaultMarginPercent} />
        </>
      )}
      <div className="my-4">
        <PricingTable type={type} data={data} onPriceClick={onPriceClick} />
      </div>
    </>
  );
};
