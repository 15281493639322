import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { ChevronDownIcon } from '@heroicons/react/solid';
import cn from 'classnames';

import { getCustomRange, serializeRange, deserializeRange } from 'helpers/reporting-helpers';
import { DateRangePicker } from 'modules/reporting/components/DateRangePicker';
import { Button } from 'modules/common';
import TableFiltersAdvanced, { FilterParam, Combobox } from 'components/TableFIltersAdvanced';
import { Badge } from 'components/MultipleCombobox';
import TableSearch from 'components/TableSearch';

type Props = {
  heading?: string;
  searchPlaceholder?: string;
  filters?: FilterParam[];
  statusFilter?: FilterParam;
  ctaContent?: React.ReactNode;
  children?: React.ReactNode;
  hideDatePicker?: boolean;
};

const TableFilters = ({ heading, searchPlaceholder, filters, ctaContent, children, hideDatePicker, statusFilter }: Props) => {
  const { push, query } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState((query?.search as string) || '');

  const { from, to } = deserializeRange({ from: query?.from as string, to: query?.to as string });
  const range = from && to ? getCustomRange(from, to) : null;

  const advancedCount = filters?.reduce((prev, curr) => {
    if (query?.[curr]) prev++;
    return prev;
  }, 0);

  const onDateChange = (range) => {
    push({ query: { ...query, ...serializeRange(range), search } }, undefined, { shallow: true });
  };

  const onSearch = () => {
    push({ query: { ...query, search } }, undefined, { shallow: true });
  };

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSearch();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [query, search]);

  return (
    <div>
      <div className="sticky top-0 w-full max-w-7xl mx-auto bg-gray-100 left-0 right-0 sm:px-6 md:px-8 pt-6 z-10">
        <div className="pb-4 border-b border-gray-300">
          {heading && (
            <div className="flex items-baseline pb-px">
              <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate mr-4">{heading}</h1>
            </div>
          )}
          <div className="flex items-end mt-3">
            {statusFilter && (
              <div className="w-44 lg:w-56">
                <Combobox filter={statusFilter} />
              </div>
            )}
            {hideDatePicker && filters?.length && <TableFiltersAdvanced filters={filters} />}
            {!hideDatePicker && (
              <div className="w-56 ml-2 lg:ml-4">
                <DateRangePicker range={range} onSelect={onDateChange} />
              </div>
            )}
            {!hideDatePicker && !!filters?.length && (
              <div className="ml-2 lg:ml-4 border">
                <Button variant="neutral-alternative" onClick={() => setIsOpen(!isOpen)}>
                  <span className="text-sm">Advanced</span>
                  {!!advancedCount && <Badge num={advancedCount} />}
                  <ChevronDownIcon className={cn('w-5 h-5 ml-2', { 'transform rotate-180': isOpen })} />
                </Button>
              </div>
            )}
            <div
              className={cn(
                'flex',
                { 'ml-auto': !(hideDatePicker && !filters?.length), 'w-full': hideDatePicker && !filters?.length },
                'justify-between'
              )}
            >
              <div className={cn('w-44 lg:w-56', { 'ml-2 lg:ml-4': !(hideDatePicker && !filters?.length) })}>
                <TableSearch placeholder={searchPlaceholder} value={search} onChange={({ target }) => setSearch(target.value)} onSearch={onSearch} />
              </div>
              {ctaContent && (
                <div className={cn({ 'ml-2 lg:ml-4': !(hideDatePicker && !filters?.length), 'ml-auto': hideDatePicker && !filters?.length })}>
                  {ctaContent}
                </div>
              )}
            </div>
          </div>
          {isOpen && !!filters?.length && <TableFiltersAdvanced isShowBorder filters={filters} />}
        </div>
      </div>
      <div className="w-full max-w-7xl mx-auto sm:px-6 md:px-8 pb-12 -mt-px">{children}</div>
    </div>
  );
};

export default TableFilters;
