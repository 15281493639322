import { getUnitShortName, validateNumber } from 'helpers/measurement-helpers';
import { SERVER_MEASUREMENT_UNIT_VALUE, useMeasurementUnit } from 'modules/common/MeasurementUnit';

const DEFAULT_UNIT_SHORT_NAME = getUnitShortName(SERVER_MEASUREMENT_UNIT_VALUE);

export const useAlterationOptionValue = () => {
  const {
    convertFromServerUnit,
    convertToServerUnit,
    measurementUnitInfo: { shortName: unitShortName },
  } = useMeasurementUnit();

  const convertFromAlterationOptionServerUnit = (rawValue: string) => {
    const sanitizedValue = Number(rawValue.replace(DEFAULT_UNIT_SHORT_NAME, '').trim());
    const isNumber = validateNumber(sanitizedValue, null);
    return isNumber ? `${convertFromServerUnit(sanitizedValue)}${unitShortName}` : rawValue;
  };

  const convertToAlterationOptionValueServerUnit = (rawValue: string) => {
    const sanitizedValue = Number(rawValue.replace(unitShortName, '').trim());
    const isNumber = validateNumber(sanitizedValue, null);
    return isNumber ? `${convertToServerUnit(sanitizedValue)}${DEFAULT_UNIT_SHORT_NAME}` : null;
  };

  return {
    unitShortName,
    convertFromAlterationOptionServerUnit,
    convertToAlterationOptionValueServerUnit,
  };
};
