import { ChangeEvent, MouseEvent, useEffect, useState, useMemo } from 'react';
import {
  OrderPageQuery,
  useUpdateOrderCustomIdMutation,
  useUpdateOrderTagsMutation,
  OrderFulfilmentStatus,
  OrderStatus,
  useRefreshOrderPriceMutation,
  UserRole,
} from '@graphql';
import { Popover } from '@headlessui/react';
import cn from 'classnames';
import { RefreshIcon } from '@heroicons/react/outline';

import { Icon } from 'components';
import EditCustomId from 'components/EditCustomId';
import { Box, BoxHeader, TextSkeleton } from 'components';
import { Button } from 'modules/common';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { toast } from 'react-toastify';
import { ArchiveButton } from 'modules/orders/components/ArchiveButton';
import { CustomerInfo } from 'modules/orders/components/CustomerInfo';
import { DuplicateOrderButton } from 'modules/orders/components/DuplicateOrderButton';
import { CancelOrderButton } from 'modules/orders/components/CancelOrderButton';
import { PrintButton } from 'modules/orders/components/PrintButton';
import { ShowroomSelect } from 'modules/orders/components/ShowroomSelect';
import { StaffSelect } from 'modules/orders/components/StaffSelect';
import { EditTags } from 'modules/orders/components/EditTags';
import { ProcessCompleteOrderButton } from 'modules/orders/components/ProcessCompleteOrderButton';
import { OrderTimeline } from 'modules/orders/components/OrderTimeline';
import { formatAsCurrency } from 'helpers/reporting-helpers';
import EditDiscount from './EditDiscount';
import { EnabledFeature } from 'layouts/EnabledFeature';
import { gql } from '@apollo/client';
import { RemakeOrderAction } from './Remake/RemakeOrderAction';
import { RelatedOrderAction } from './RelatedOrders';

export const OrderHeader = ({
  order,
  loading: isLoading,
  onRemakeClick,
}: {
  order: OrderPageQuery['order'];
  loading?: boolean;
  onRemakeClick: () => void;
}) => {
  const {
    fulfilment,
    tailor,
    isSuperAdmin,
    user: { role },
    shouldAllowRemake,
  } = useActiveSession();
  const isStaff = role === UserRole.Staff;
  const [isEditOrderIdOpen, setIsEditOrderIdOpen] = useState(false);
  const [isEditTagOpen, setIsEditTagOpen] = useState(false);
  const [customOrderId, setCustomOrderId] = useState<string | null | undefined>();
  const [updateOrderTags, { loading: isSavingOrderTags }] = useUpdateOrderTagsMutation();
  const [updateOrderCustomId, { loading: isSavingOrderCustomId }] = useUpdateOrderCustomIdMutation();
  const [refreshOrderPrice, { loading: refreshingOrderPrice }] = useRefreshOrderPriceMutation();

  const isSaving = useMemo(() => isSavingOrderTags || isSavingOrderCustomId, [isSavingOrderTags, isSavingOrderCustomId]);

  const hasMissingTemplates = useMemo(() => order?.items.some((i) => i.designs.some((d) => !d.hasSubTemplate)), [order]);
  const isRemadeOrder = !!order?.relatedOrders?.remake?.originalOrderId;
  useEffect(() => {
    setCustomOrderId(order?.customId);
  }, [order?.customId]);

  const handleClickSaveOrderCustomId = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      if (customOrderId !== order?.customId) {
        await updateOrderCustomId({
          variables: {
            orderId: order.id,
            customId: customOrderId,
          },
        });
        toast.success('Order ID updated.');
      }
    } catch {
      toast.error('Unable to update order ID.');
    } finally {
      setIsEditOrderIdOpen(false);
    }
  };

  const getOrderStatusColour = () => {
    switch (order.orderStatus.key) {
      case OrderStatus.Complete:
        return 'emerald';
      case OrderStatus.Draft:
        return 'blue';
      default:
        return 'yellow';
    }
  };

  const getFulfilmentStatusColour = () => {
    switch (order.orderFulfilmentStatus.key) {
      case OrderFulfilmentStatus.Fulfilled:
        return 'emerald';
      case OrderFulfilmentStatus.Unfulfilled:
        return 'red';
      default:
        return 'yellow';
    }
  };

  const handleRemoveTag = async (tagId: string) => {
    await updateOrderTags({
      variables: {
        orderId: order?.id,
        updateOrderInput: {
          tagIds: order.tags.filter((tag) => tag.id !== tagId).map((tag) => tag.id),
        },
      },
    });
    toast.success('Tags updated.');
  };

  if (isLoading) {
    return (
      <Box hasPadding={false} allowSkeletonLoading>
        <BoxHeader>
          <div className="flex justify-between py-2">
            <TextSkeleton className="w-1/2" />
            <TextSkeleton className="w-1/6" />
          </div>
        </BoxHeader>
        <div className="flex p-6">
          <TextSkeleton className="w-20 mr-8 h-8" />
          <TextSkeleton className="w-20 mr-8 h-8" />
          <TextSkeleton className="w-20 mr-8 h-8" />
          <TextSkeleton className="w-20 mr-8 h-8" />
          <TextSkeleton className="w-20 mr-8 h-8" />
          <TextSkeleton className="w-20 mr-8 h-8" />
        </div>
      </Box>
    );
  }

  const isRemake = !!order?.relatedOrders?.remake?.originalOrderId;
  if (!isLoading && !order) {
    return <div className="h-full w-full flex justify-center items-center">Couldn&apos;t load order.</div>;
  }

  return (
    <>
      <Box hasPadding={false}>
        <BoxHeader>
          <div className="flex flex-col lg:flex-row lg:justify-between py-2">
            {order?.orderStatus.name && (
              <div className="flex mb-4 lg:mb-0 flex-col">
                <div className="flex items-center">
                  {isEditTagOpen && (
                    <EditTags defaultTags={order?.tags} handleCancel={() => setIsEditTagOpen(false)} onSuccess={() => setIsEditTagOpen(false)} />
                  )}
                  {/* TODO: move edit custom order it into it's own component */}
                  {isEditOrderIdOpen && order.orderStatus.key === OrderStatus.Draft && (
                    <EditCustomId
                      onSave={handleClickSaveOrderCustomId}
                      onCancel={(event: MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        setIsEditOrderIdOpen(false);
                        setCustomOrderId(order?.customId);
                      }}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setCustomOrderId(event.target.value ?? null);
                      }}
                      placeholder="Edit Order ID"
                      value={customOrderId}
                      isLoading={isLoading || isSaving}
                    />
                  )}
                  {!isEditTagOpen && !isEditOrderIdOpen && (
                    <div className="flex items-center">
                      <h1 className="uppercase text-2xl font-bold py-1">#{order?.customId}</h1>
                      {order.relatedOrders?.remake?.originalOrderId && (
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 ml-4 text-orange-800 cursor-pointer`}
                          onClick={onRemakeClick}
                        >
                          Remake
                        </span>
                      )}
                      <>
                        <span
                          className={`mr-4 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${getOrderStatusColour()}-100 ml-4 text-${getOrderStatusColour()}-800`}
                        >
                          <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${getOrderStatusColour()}-400`} fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          {order.orderStatus.name}
                        </span>
                      </>
                      {order?.tags.map((tag, count) => {
                        return (
                          <div
                            key={`order-tags-${count}`}
                            className={cn('rounded-full text-xs px-2.5 py-0.5 mr-2 flex items-baseline font-medium', {
                              'bg-blue-100 text-blue-800': !isSaving,
                              'bg-blue-50 text-blue-400': isSaving,
                            })}
                          >
                            {tag.name}
                            <div onClick={() => !isSaving && handleRemoveTag(tag.id)} className="flex">
                              <Icon icon="cross" className={cn('ml-1 text-sky-400', { 'cursor-pointer': !isSaving })} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="text-gray-400 text-xs my-2.5">
                    Created{' '}
                    {new Date(order?.createdAt.origin).toLocaleDateString('en-us', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </div>
                  <div className="flex items-center flex-wrap gap-y-3">
                    {(order.orderStatus.key === OrderStatus.Draft || isSuperAdmin) && (
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          setIsEditOrderIdOpen(true);
                        }}
                        isText
                        isDisabled={isSaving || isEditOrderIdOpen || isEditTagOpen}
                        className="mr-6"
                      >
                        <div className="text-gray-500 flex items-center text-xs">
                          <Icon icon="pencil-alt" className="mr-2" />
                          Edit Order ID
                        </div>
                      </Button>
                    )}

                    <Button
                      onClick={() => setIsEditTagOpen(true)}
                      isText
                      isDisabled={isSaving || isEditOrderIdOpen || isEditTagOpen}
                      className="mr-6"
                    >
                      <div className="text-gray-500 flex items-center text-xs">
                        <Icon icon="tag" className="mr-2" />
                        Add tag
                      </div>
                    </Button>

                    <DuplicateOrderButton
                      order={order}
                      isDisabled={isSaving || isEditOrderIdOpen || isEditTagOpen || hasMissingTemplates || isRemadeOrder}
                    />
                    {shouldAllowRemake && <RemakeOrderAction className="h-6" order={order} isDisabled={hasMissingTemplates} />}
                    {![OrderStatus.Cancelled, OrderStatus.Processing].includes(order?.orderStatus.key) && (
                      <>
                        <ArchiveButton
                          orderId={order?.id}
                          isArchived={order?.orderStatus.key === OrderStatus.Archived}
                          isDisabled={isSaving || isEditOrderIdOpen || isEditTagOpen || (isStaff && order.orderStatus.key !== OrderStatus.Draft)}
                        />
                      </>
                    )}
                    {isSuperAdmin && <CancelOrderButton orderId={order?.id} isDisabled={order?.orderStatus?.key === OrderStatus.Cancelled} />}
                    {fulfilment === 'PDF' && <PrintButton />}
                    <RelatedOrderAction className="h-6" relatedOrders={order.relatedOrders} />
                  </div>
                </div>
              </div>
            )}
            {fulfilment !== 'PDF' && <ProcessCompleteOrderButton order={order} />}
          </div>
        </BoxHeader>

        <div className="flex p-6 font-semibold text-xs uppercase">
          <div className="mr-12">
            <div className="text-gray-500">Fulfilment status</div>
            <div className={`text-${getFulfilmentStatusColour()}-500`}>{order.orderFulfilmentStatus.name}</div>
          </div>
          <div className="mr-12">
            <div className="text-gray-500">Customer</div>
            <Popover className="relative flex">
              <Popover.Button>
                <div className="text-left uppercase text-blue-500 underline font-semibold">
                  {order?.customer.firstName} {order?.customer.lastName}
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute top-full z-10 mt-2 w-80">
                <CustomerInfo email={order.customer.email} />
              </Popover.Panel>
            </Popover>
          </div>
          <div className="mr-12">
            <div className="text-gray-500 whitespace-nowrap">Sales rep</div>
            <Popover className="relative flex">
              <Popover.Button>
                <div className="flex items-center text-left uppercase text-blue-500 font-semibold">
                  {order?.salesRep ? `${order?.salesRep.firstName} ${order?.salesRep.lastName}` : 'Please choose'}
                  <Icon icon="edit" className="ml-1" />
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute top-full z-10 mt-2 w-80">
                {({ close }) => (
                  <Box>
                    <StaffSelect defaultSalesRep={order?.salesRep} orderId={order?.id} onSuccess={close} />
                  </Box>
                )}
              </Popover.Panel>
            </Popover>
          </div>
          <div className="mr-12">
            <div className="text-gray-500">Showroom</div>
            <Popover className="relative flex">
              <Popover.Button>
                <div className="flex items-center text-left uppercase text-blue-500 font-semibold">
                  {order?.showroom ? order.showroom.name : 'Please choose'}
                  <Icon icon="edit" className="ml-1" />
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute top-full z-10 mt-2 w-80">
                {({ close }) => (
                  <Box>
                    <ShowroomSelect defaultShowroom={order?.showroom} orderId={order?.id} onSuccess={close} />
                  </Box>
                )}
              </Popover.Panel>
            </Popover>
          </div>

          {order?.orderStatus?.key === OrderStatus.Draft && (
            <div className="self-end ml-auto flex">
              <>
                <EnabledFeature shouldCheckEnv={true} featureName="DISCOUNTS">
                  <div className="group mr-6">
                    <div className="text-gray-500">Discount</div>
                    <Popover className="relative flex">
                      <Popover.Button>
                        <div className="flex items-center text-left uppercase text-blue-500 font-semibold">
                          {order?.discount ? order?.discount?.code : 'Please choose'}
                          <Icon icon="edit" className="ml-1" />
                        </div>
                      </Popover.Button>

                      <Popover.Panel className="absolute top-full z-10 mt-2 w-80">
                        {({ close }) => (
                          <Box>
                            <EditDiscount
                              onCancel={() => close()}
                              onSave={() => void 0}
                              onChange={() => void 0}
                              placeholder="Enter a discount code"
                              discount={order?.discount}
                              totalValue={order?.totalRetail?.amount}
                              discountedValue={order?.totalRetail?.discountedPrice}
                              orderId={order?.id}
                              orderStatus={order?.orderStatus?.key}
                              tailor={tailor}
                            />
                          </Box>
                        )}
                      </Popover.Panel>
                    </Popover>
                  </div>
                </EnabledFeature>
                <div className="group flex divide-x bg-gray-50 mr-6">&nbsp;</div>
                <div className="group">
                  <div className="flex items-center text-gray-500">
                    <span className="mr-0.5">Total</span>
                    {isSuperAdmin && (
                      <button
                        className={cn('text-blue-400 hover:text-blue-500 transition-opacity transition-colors opacity-0 group-hover:opacity-100', {
                          'animate-spin': refreshingOrderPrice,
                        })}
                        onClick={() => refreshOrderPrice({ variables: { orderId: order.id } })}
                        aria-label="refresh order price"
                      >
                        <RefreshIcon className="w-3 h-3" />
                      </button>
                    )}
                  </div>
                  <div className="text-base">
                    {tailor?.locale &&
                      tailor?.currency &&
                      formatAsCurrency(isRemake ? 0 : order?.totalRetail?.discountedPrice, tailor?.locale, tailor?.currency, false)}
                  </div>
                  {order?.discount && (
                    <div className="text-base line-through">
                      {tailor?.locale && tailor?.currency && formatAsCurrency(order?.totalRetail?.amount, tailor?.locale, tailor?.currency, false)}
                    </div>
                  )}
                </div>
              </>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

OrderHeader.mutations = {
  updateOrderCustomId: gql`
    ${OrderTimeline.fragments.root}
    ${ProcessCompleteOrderButton.fragments.root}

    mutation UpdateOrderCustomId($orderId: ID!, $customId: String!) {
      updateOrderCustomId(orderId: $orderId, customId: $customId) {
        id
        customId
        ...OrderTimeline
        ...ProcessCompleteOrderButton
      }
    }
  `,
  refreshOrderPrice: gql`
    mutation RefreshOrderPrice($orderId: ID!) {
      refreshOrderPrice(orderId: $orderId) {
        id
        totalRetail {
          amount
          discountedPrice
        }
        items {
          id
          retail {
            total
            base
            lining
            designOptions
            discountedPrice
            discountedAmount
          }
        }
      }
    }
  `,
};
