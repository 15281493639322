import React, { createContext } from 'react';
import { gql } from '@apollo/client';
import { FulfilmentType, UseActiveSessionFragmentFragment, useUseActiveSessionQuery } from '@graphql';

export type ActiveSessionInfo = {
  loading: boolean;
  activeSession: UseActiveSessionFragmentFragment;
  tailor: UseActiveSessionFragmentFragment['tailor'];
  tailorSlug: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  fulfilment: FulfilmentType;
  defaultShowroom: UseActiveSessionFragmentFragment['user']['defaultShowroom'];
  user: UseActiveSessionFragmentFragment['user'];
  defaultMeasurementUnit: UseActiveSessionFragmentFragment['tailor']['offeringConfig']['defaultMeasurementUnit'];
  shouldAllowRemake: boolean;
};

export const ActiveSessionContext = createContext<ActiveSessionInfo | undefined>(undefined);

export const ActiveSessionProvider = ({ children }) => {
  const { data, loading } = useUseActiveSessionQuery({ fetchPolicy: 'no-cache' });

  const activeSession = {
    loading,
    activeSession: data?.activeSession,
    tailor: data?.activeSession.tailor,
    tailorSlug: data?.activeSession.tailor.slug,
    isAdmin: data?.activeSession.user.role === 'ADMIN' || false,
    isSuperAdmin: data?.activeSession.user.role === 'SUPER_ADMIN' || false,
    fulfilment: data?.activeSession.tailor.fulfilment,
    defaultShowroom: data?.activeSession.user.defaultShowroom,
    user: data?.activeSession.user,
    defaultMeasurementUnit: data?.activeSession.tailor.offeringConfig?.defaultMeasurementUnit,
    shouldAllowRemake: data?.activeSession.tailor.offeringConfig?.shouldAllowRemake,
  };

  return <ActiveSessionContext.Provider value={activeSession}>{data ? children : null}</ActiveSessionContext.Provider>;
};

ActiveSessionProvider.fragments = {
  activeSession: gql`
    fragment UseActiveSessionFragment on SessionResolverType {
      user {
        id
        role
        email
        firstName
        lastName
        state
        createdAt {
          formatted
          origin
        }
        defaultShowroom {
          id
          name
          customId
          externalId
          isActive
        }
      }
      tailor {
        slug
        name
        locale
        currency
        timezone
        logoUrl
        fulfilment
        config {
          availableFits
          availableFeatures
        }
        offeringConfig {
          defaultMeasurementUnit
          shouldAllowRemake
        }
        defaultMarginPercent
      }
    }
  `,
};

ActiveSessionProvider.query = gql`
  ${ActiveSessionProvider.fragments.activeSession}
  query UseActiveSession {
    activeSession {
      ...UseActiveSessionFragment
    }
  }
`;
