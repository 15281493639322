import { useGetFabricIndividualPricingQuery, useUpsertManyIndividualPricingMutation } from '@graphql';
import { ErrorPanel } from 'components/ErrorPanel';
import { Spinner } from 'components/Spinner';
import { useIndividualPricingReducer, actions, getInputParameters } from './IndividualPricingState';
import { GET_FABRIC_INDIVIDUAL_PRICING } from './getFabricIndividualPricing.gql';
import { toast } from 'react-toastify';
import { FabricPriceMatrix } from './FabricPriceMatrix';
import { AddNewFabricSelect } from './AddNewFabricSelect';
import { Button } from 'modules/common/components/Button';
import { ImportCsvButton } from './ExportCsvButton';
import { ExportCsvButton } from './ExportCsvButton/ExportCsvButton';
import { ExampleCsvButton } from './ExampleCsvButton';
import { isApolloError } from '@apollo/client';

export const IndividualPriceTab = () => {
  const { state, dispatch } = useIndividualPricingReducer();
  const { error, loading } = useGetFabricIndividualPricingQuery({
    onCompleted: (data) => dispatch(actions.initialize(data)),
  });
  const [upsertPrices, { loading: isSaving }] = useUpsertManyIndividualPricingMutation({
    refetchQueries: [GET_FABRIC_INDIVIDUAL_PRICING],
    awaitRefetchQueries: true,
  });

  const handleUpdate = async () => {
    const input = getInputParameters(state.rows);
    try {
      await upsertPrices({ variables: { input } });
      toast.success('Prices were update correctly');
    } catch (e) {
      toast.error("Couldn't update prices, please try again");
    }
  };

  const handleImport = async (file: File) => {
    try {
      await upsertPrices({ variables: { input: { file } } });
      toast.success('Pricing successfully updated');
    } catch (e) {
      if (isApolloError(e) && e.graphQLErrors.length > 0) {
        const {
          extensions: { code, lineErrorDetails, missingFabricCodes },
        } = e.graphQLErrors[0];
        switch (code) {
          case 'BAD_FORMAT':
            toast.error(`Fail to import prices, bad format: \n ${lineErrorDetails.join('\n')}`, {
              autoClose: false,
              className: 'whitespace-break-spaces',
            });
            return;
          case 'MISSING_FABRIC_CODES':
            toast.error(`Fail to import prices, following fabric codes not found: \n ${missingFabricCodes.join(', ')}`, { autoClose: false });
            return;
        }
      }
      toast.error("Couldn't update prices, please try again");
    }
  };

  if (loading)
    return (
      <div className="absolute inset-0 m-auto flex items-center justify-center">
        <Spinner />
      </div>
    );
  if (error) {
    return <ErrorPanel />;
  }

  const arePricesChanged = state.rows.some((r) => r.prices.some((p) => p.isUpdated));

  return (
    <div>
      <div className="flex flex-col mb-4">
        <div className="flex gap-4 mt-1 mb-3.5">
          <ExportCsvButton rows={state.rows} isDisabled={isSaving || arePricesChanged} />
          <ImportCsvButton isDisabled={isSaving} onImportFile={handleImport} />
          <ExampleCsvButton headers={state.headers} />
        </div>
        <div className="flex gap-4">
          <AddNewFabricSelect
            isReadOnly={isSaving}
            currentFabricCodes={state.rows.map((r) => r.fabricCode)}
            onNewFabricSelected={(fabricCode, fabricLabel) => dispatch(actions.addFabric(fabricCode, fabricLabel))}
          />
          <Button className="w-40 flex justify-center" variant="positive" onClick={handleUpdate} isDisabled={isSaving || !arePricesChanged}>
            Update
          </Button>
        </div>
      </div>
      <FabricPriceMatrix
        headers={state.headers}
        rows={state.rows}
        isReadOnly={isSaving}
        onChangeAmount={(i, j, rawAmount) => dispatch(actions.updatePrice(i, j, rawAmount))}
        onSwitchPriceEditMode={(i, j) => dispatch(actions.switchPriceEditMode(i, j))}
      />
    </div>
  );
};
