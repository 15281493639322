import cn from 'classnames';

import { GetProductPricingQuery, GetLiningPricingQuery, ProductCategory } from '@graphql';
import { formatAsCurrency } from 'helpers/reporting-helpers';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { Price, PriceType, OnPriceClick } from 'modules/pricing/pricing.types';

interface Props {
  type: PriceType;
  data: GetProductPricingQuery | GetLiningPricingQuery;
  onPriceClick: OnPriceClick;
}

export type LookupMap = Record<string, Record<ProductCategory, Price>>;

const createMatrixLookup = (prices: Price[] = []) =>
  prices.reduce((prev, curr) => {
    const {
      productCategory: category,
      tier: { name: tierName },
    } = curr;

    if (!prev[tierName]) prev[tierName] = {} as Record<ProductCategory, Price>;
    if (!prev[tierName][category]) prev[tierName][category] = {} as Price;

    prev[tierName][category] = curr;

    return prev;
  }, {} as LookupMap);

export const PricingTable = ({ type, data, onPriceClick }: Props) => {
  const { tailor } = useActiveSession();
  const {
    prices,
    tiers,
    settings: { productCategories },
  } = data;

  const lookup = createMatrixLookup(prices);

  return (
    <table className="w-full text-xs">
      <thead>
        <tr>
          <th className="p-4 bg-yellow-200 border border-gray-300 text-center">Tier</th>
          {productCategories.map((category) => (
            <th key={category.key} className="p-4 bg-gray-50 border border-gray-300 text-center">
              {category.truncatedName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tiers
          .map((tier) => ({ tier, categories: productCategories.map((p) => p.key) }))
          .map(({ tier, categories }, i) => (
            <tr key={tier.id} className={cn({ 'bg-gray-100': i % 2 === 0, 'bg-gray-50': i % 2 === 1 })}>
              <th className={cn('p-4 border border-gray-300 text-center', { 'bg-amber-100': i % 2 === 0, 'bg-amber-50': i % 2 === 1 })}>
                {tier.name}
              </th>
              {categories.map((category) => {
                const price = lookup[tier.name]?.[category];

                return (
                  <td
                    key={`${tier}-${category}`}
                    className={cn('p-4 border border-gray-300 text-center cursor-pointer hover:bg-blue-100', { 'pointer-events-none': !price })}
                    onClick={() =>
                      onPriceClick({ ...price, productCategorySetting: productCategories.find((p) => p.key === price.productCategory), type })
                    }
                  >
                    {price ? formatAsCurrency(price.amount, tailor.locale, tailor.currency) : ''}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
