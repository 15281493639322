import { ProductCategory } from '@graphql';

import ACTIVE from 'assets/svg/templates/ACTIVE.svg';
import BLOUSON from 'assets/svg/templates/BLOUSON.svg';
import BOMBER from 'assets/svg/templates/BOMBER.svg';
import CAMP_SHIRT from 'assets/svg/templates/CAMP_SHIRT.svg';
import CASUAL_SHIRT from 'assets/svg/templates/CASUAL_SHIRT.svg';
import FIELD_JACKET from 'assets/svg/templates/FIELD_JACKET.svg';
import FLIGHT from 'assets/svg/templates/FLIGHT.svg';
import HARRINGTON from 'assets/svg/templates/HARRINGTON.svg';
import HYBRID_JACKET from 'assets/svg/templates/HYBRID_JACKET.svg';
import JACKET from 'assets/svg/templates/JACKET.svg';
import LADIES_JACKET_SKIRT from 'assets/svg/templates/LADIES_JACKET_SKIRT.svg';
import LADIES_JACKET_PANTS from 'assets/svg/templates/LADIES_JACKET_PANTS.svg';
import LADIES_JACKET_PANTS_SKIRT from 'assets/svg/templates/LADIES_JACKET_PANTS_SKIRT.svg';
import MENS_JACKET_PANTS from 'assets/svg/templates/MENS_JACKET_PANTS.svg';
import MENS_JACKET_PANTS_VEST from 'assets/svg/templates/MENS_JACKET_PANTS_VEST.svg';
import MORNING_COAT from 'assets/svg/templates/MORNING_COAT.svg';
import OVERCOAT from 'assets/svg/templates/OVERCOAT.svg';
import OVERSHIRT from 'assets/svg/templates/OVERSHIRT.svg';
import SAFARI_JACKET from 'assets/svg/templates/SAFARI_JACKET.svg';
import PANTS from 'assets/svg/templates/PANTS.svg';
import SHIRT_V2 from 'assets/svg/templates/SHIRT_V2.svg';
import SHORT_SLEEVE_SHIRT_V2 from 'assets/svg/templates/SHORT_SLEEVE_SHIRT_V2.svg';
import SHORTS from 'assets/svg/templates/SHORTS.svg';
import TAL_SHIRT from 'assets/svg/templates/TAL_SHIRT.svg';
import TAL_SHORT_SLEEVE_SHIRT from 'assets/svg/templates/TAL_SHORT_SLEEVE_SHIRT.svg';
import TRENCH_COAT from 'assets/svg/templates/TRENCH_COAT.svg';
import VEST from 'assets/svg/templates/VEST.svg';
import WOMEN_JACKET from 'assets/svg/templates/WOMEN_JACKET.svg';
import WOMEN_PANTS from 'assets/svg/templates/WOMEN_PANTS.svg';
import WOMEN_SKIRT from 'assets/svg/templates/WOMEN_SKIRT.svg';
import PREMIUM_JACKET from 'assets/svg/templates/JACKET.svg';
import PREMIUM_VEST from 'assets/svg/templates/VEST.svg';
import PREMIUM_PANTS from 'assets/svg/templates/PANTS.svg';
import PREMIUM_SHIRT_V2 from 'assets/svg/templates/SHIRT_V2.svg';
import PREMIUM_SHORT_SLEEVE_SHIRT_V2 from 'assets/svg/templates/SHORT_SLEEVE_SHIRT_V2.svg';
import PREMIUM_OVERCOAT from 'assets/svg/templates/OVERCOAT.svg';
import MENS_PREMIUM_JACKET_PANTS from 'assets/svg/templates/MENS_JACKET_PANTS.svg';
import MENS_PREMIUM_JACKET_PANTS_VEST from 'assets/svg/templates/MENS_JACKET_PANTS_VEST.svg';

const TemplateImages: Record<ProductCategory, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  ACTIVE,
  BLOUSON,
  BOMBER,
  CAMP_SHIRT,
  CASUAL_SHIRT,
  FIELD_JACKET,
  FLIGHT,
  HARRINGTON,
  HYBRID_JACKET,
  JACKET,
  LADIES_JACKET_PANTS,
  LADIES_JACKET_PANTS_SKIRT,
  LADIES_JACKET_SKIRT,
  MENS_JACKET_PANTS,
  MENS_JACKET_PANTS_VEST,
  MORNING_COAT,
  OVERCOAT,
  OVERSHIRT,
  PANTS,
  SAFARI_JACKET,
  SHIRT: SHIRT_V2,
  SHIRT_V2,
  SHORT_SLEEVE_SHIRT: SHORT_SLEEVE_SHIRT_V2,
  SHORT_SLEEVE_SHIRT_V2,
  SHORTS,
  TAL_SHIRT,
  TAL_SHORT_SLEEVE_SHIRT,
  TRENCH_COAT,
  VEST,
  WOMEN_JACKET,
  WOMEN_PANTS,
  WOMEN_SKIRT,
  PREMIUM_JACKET,
  PREMIUM_PANTS,
  PREMIUM_VEST,
  PREMIUM_SHIRT_V2,
  PREMIUM_SHORT_SLEEVE_SHIRT_V2,
  PREMIUM_OVERCOAT,
  MENS_PREMIUM_JACKET_PANTS,
  MENS_PREMIUM_JACKET_PANTS_VEST,
};

export const getTemplateImage = (productCategory: ProductCategory) => TemplateImages?.[productCategory] || null;
