import { gql } from '@apollo/client';

import { OrderItemSummaryDrawerFragmentFragment, useOrderItemSummaryDrawerQuery } from '@graphql';
import { SlideoverPanel } from 'components';
import { Button, TaperLoading } from 'modules/common';
import { OrderItemSummaryDrawerDesignInfo } from './OrderItemSummaryDrawerDesignInfo';
import { getCmtLiningComponents, isCmtLiningOptionSelected, isCustomLining } from 'modules/fabric';

const FabricSummary = ({ className, coreFabric }: { className?: string; coreFabric: OrderItemSummaryDrawerFragmentFragment['fabric'] }) => {
  let fabricDescription = '';
  if (!coreFabric || !coreFabric.id) {
    fabricDescription = 'No fabric chosen';
  } else if (coreFabric?.code && coreFabric?.name) {
    fabricDescription = `${coreFabric?.code} / ${coreFabric?.name}`;
  } else {
    fabricDescription = coreFabric?.code;
  }

  return (
    <div className={className}>
      <span className="font-medium">Fabric:</span>
      <div className="mt-2">{fabricDescription}</div>
    </div>
  );
};

interface Props {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  orderItemId: string;
}

export const OrderItemSummaryDrawer = ({ isOpen, onClose, orderItemId }: Props) => {
  const { data, loading: isLoading } = useOrderItemSummaryDrawerQuery({ variables: { orderItemId } });
  const item = data?.orderItem;

  const requiresLining = item?.productCategorySetting.requiresLining;
  const cmtLiningComponents = item && isCmtLiningOptionSelected(item.liningOptions) ? getCmtLiningComponents(item.liningOptions) : null;

  return (
    <SlideoverPanel
      isOpen={isOpen}
      setIsOpen={onClose}
      maxWidthCss="max-w-6xl"
      showCancel={false}
      showExit={false}
      submitButton={<Button onClick={() => onClose(false)}>Close</Button>}
    >
      {item && !isLoading ? (
        <>
          {item.designs.length > 1 && <div className="text-lg font-semibold border-b border-gray-200 pb-8 mb-8">{item.name}</div>}
          <div className="mb-20 pl-4">
            <div className="text-lg font-medium border-b border-gray-200 pb-8">Estimated Delivery Date</div>
            <div className="mt-6">
              <div className="text-lg font-medium pb-2 flex items-center justify-start gap-2">
                <div className="inline-flex items-center bg-indigo-50 text-indigo-500 py-1 px-2 rounded text-sm whitespace-nowrap mr-2">
                  {item?.estimatedDeliveryDate?.formatted || '-'}
                </div>
              </div>
              <p className="font-normal text-xs text-gray-700 mb-1">
                Disclaimer: The date provided is an approximation of when the item will arrive and is not guaranteed.
              </p>
            </div>
          </div>
          <div className="mb-20 pl-4">
            <div className="text-lg font-medium border-b border-gray-200 pb-8">{requiresLining ? 'Fabric and lining' : 'Fabric'}</div>
            <div className="mt-6">
              {!item.fabric && !item.lining && !cmtLiningComponents && (
                <div>There is no chosen {requiresLining ? 'fabric or lining.' : 'fabric.'}</div>
              )}
              {(item?.fabric || item?.lining || cmtLiningComponents) && (
                <div>
                  <FabricSummary className="mb-6" coreFabric={item?.fabric} />
                  {requiresLining && (
                    <>
                      {!item.lining && (
                        <div className="mb-4">
                          <div className="font-medium">CMT lining: </div>
                          <div className="mt-2">
                            <div>Vendor - {cmtLiningComponents.vendor}</div>
                            <div>Quantity - {cmtLiningComponents.quantity} (metres)</div>
                            <div>Code - {cmtLiningComponents.code}</div>
                            {cmtLiningComponents.composition && isCustomLining(cmtLiningComponents.vendor) && (
                              <div>Composition - {cmtLiningComponents.composition}</div>
                            )}
                          </div>
                        </div>
                      )}
                      {item.lining && (
                        <div className="mb-4">
                          <div className="font-medium">Lining: </div>
                          <div className="mt-2">{item?.lining?.code || 'No lining chosen'}</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {item.designs.length === 1 && <OrderItemSummaryDrawerDesignInfo design={item.designs[0]} />}
          {item.designs.length > 1 &&
            item.designs.map((design) => (
              <div key={`summary-drawer-design-info-${design.id}`} className="pl-4">
                <div className="text-lg font-semibold border-b border-gray-200 pb-8 mb-8">{design.garmentCategory.name}</div>
                <OrderItemSummaryDrawerDesignInfo design={design} />
              </div>
            ))}
        </>
      ) : (
        <TaperLoading isCentred />
      )}
    </SlideoverPanel>
  );
};

OrderItemSummaryDrawer.fragments = {
  orderItem: gql`
    fragment OrderItemSummaryDrawerFragment on OrderItem {
      id
      name
      productCategory
      productCategorySetting {
        key
        requiresLining
      }
      estimatedDeliveryDate {
        formatted(format: "ll")
      }
      fabric {
        id
        code
        name
      }
      lining {
        id
        code
      }
      liningOptions {
        enableCmtLining
        enableCustomLining
        cmtOptions {
          key
          value
        }
        cmtDefaultOptions {
          key
          value
        }
      }
      designs {
        id
        garmentCategory {
          key
          name
        }
        measurement {
          name
          size
          type {
            name
            key
          }
          finishedMeasurementFields {
            key
            value
            label
          }
          tweaks {
            __typename
            ... on GarmentMeasurementTweak {
              key
              value
              name
            }
            ... on TryOnMeasurementTweak {
              typeKey
              itemKey
              option
              typeName
              itemName
            }
          }
        }
        options {
          id
          name
          value
          designTypeOffering {
            id
            code
            name
          }
        }
      }
    }
  `,
};

OrderItemSummaryDrawer.query = gql`
  ${OrderItemSummaryDrawer.fragments.orderItem}

  query OrderItemSummaryDrawer($orderItemId: ID!) {
    orderItem(orderItemId: $orderItemId, isTemplate: false) {
      ...OrderItemSummaryDrawerFragment
    }
  }
`;
