import { useFabricListLazyQuery } from '@graphql';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

type Props = {
  isReadOnly: boolean;
  currentFabricCodes: string[];
  onNewFabricSelected: (fabricCode: string, fabricLabel: string) => void;
};

export const AddNewFabricSelect = ({ isReadOnly, currentFabricCodes, onNewFabricSelected }: Props) => {
  const [searchFabrics, { loading: isLoading }] = useFabricListLazyQuery({ fetchPolicy: 'cache-first' });

  const searchOptions = useMemo(
    () =>
      debounce((value: string, callback: (options: Option[]) => void) => {
        searchFabrics({ variables: { code: value } }).then(({ data }) => {
          const newOptions = data.searchCoreFabrics
            .map((fabric) => ({
              value: fabric.code,
              label: fabric.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
          callback(newOptions);
        });
      }, 500),
    [searchFabrics]
  );

  return (
    <AsyncCreatableSelect<Option>
      cacheOptions
      className="text-sm w-96"
      placeholder={'Search fabric by name or code'}
      isDisabled={isReadOnly}
      value={null}
      onChange={(option) => onNewFabricSelected(option.value, option.label)}
      styles={{
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? '2px solid #6b7280' : '1px solid #d1d5db',
          boxShadow: 'none',
          '*': {
            boxShadow: 'none !important',
          },
          '&:hover': {
            border: '2px solid #6b7280',
          },
          borderRadius: '6px',
          padding: '2px 0',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 50 }),
      }}
      menuPortalTarget={document.body}
      isSearchable={true}
      isOptionDisabled={(option) => currentFabricCodes.includes(option.value)}
      loadOptions={(value, callback) => {
        searchOptions(value, callback);
      }}
      isLoading={isLoading}
    />
  );
};
