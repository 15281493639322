import { ReactNode, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuth } from 'providers/auth.provider';
import { ActiveSessionProvider } from 'providers/active-session.provider';
import { Router, useRouter } from 'next/router';
import { TheHeader } from 'components';
import AppUpdate from 'components/AppUpdate';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureAsync } from 'hooks';
import mixpanelService from 'services/mixpanel.service';
import { useActiveSessionQuery } from '@graphql';
import { useIntercomInitializer } from 'hooks/useIntercomInitializer';

const contextClass = {
  success: 'bg-emerald-500',
  error: 'bg-red-500',
  info: 'bg-blue-500',
  warning: 'bg-orange-500',
  default: 'bg-gray-800',
  dark: 'bg-white-600 font-gray-300',
};

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const { enabled: auth0AuthFF, loading: auth0AuthFFLoading } = useFeatureAsync('AUTH_AUTH0');

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      mainRef.current?.scrollTo(0, 0);
    });
  }, []);

  const router = useRouter();
  const { isAuthenticating: isFirebaseAuthenticating, isAuthenticated: isFirebaseAuthenticated } = useAuth();
  const { isLoading: isAuthenticating, isAuthenticated, error: auth0Error } = useAuth0();
  const { data, error: sessionError } = useActiveSessionQuery({ fetchPolicy: 'cache-first', skip: !isAuthenticated });
  const [isLoading, setIsLoading] = useState(true);

  useIntercomInitializer(data?.activeSession);

  const error = auth0Error || sessionError;
  useEffect(() => {
    if (error) {
      let errorMessage = error.message;
      if (errorMessage === 'client requires organization membership, but user does not belong to any organization') {
        errorMessage = `User does not belong to an organisation`;
      }
      router.push(`/login?redirect=false&error=${errorMessage}`);
      return;
    } else if (auth0AuthFF) {
      if (isAuthenticating) return;
      if (!isAuthenticated) {
        router.push('/login');
        return;
      }
    } else {
      if (isFirebaseAuthenticating) return;
      if (!isFirebaseAuthenticated) {
        router.push('/login');
        return;
      }
    }

    setIsLoading(false);
  }, [isAuthenticating, isAuthenticated, isFirebaseAuthenticating, isFirebaseAuthenticated]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const route = router.route;
      const queryParams = router.query;
      //Send track event when new pages is loaded
      mixpanelService.trackPage({ url, route, ...queryParams });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [data, router.events]);

  if (isLoading || auth0AuthFFLoading) {
    return null;
  }

  return (
    <ActiveSessionProvider>
      <TheHeader />
      <div className="h-screen flex overflow-hidden bg-gray-100 pt-16 print:overflow-visible print:w-full print:h-full print:bg-white">
        <div className="flex flex-col w-0 flex-1 overflow-hidden print:overflow-visible">
          <main className="flex-1 relative overflow-y-auto focus:outline-none print:overflow-visible" ref={mainRef}>
            <AppUpdate />
            {children}
          </main>
        </div>

        <ToastContainer
          toastClassName={({ type }) =>
            contextClass[type || 'default'] + ' relative flex p-2 min-h-10 rounded justify-between overflow-hidden cursor-pointer print:hidden'
          }
          bodyClassName={() => 'text-sm font-white block p-2'}
          newestOnTop
          closeOnClick
          pauseOnHover
        />
      </div>
    </ActiveSessionProvider>
  );
};

export { AppLayout };
