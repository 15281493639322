import { Select } from 'components';
import { useGarmentCategoriesSelectQuery, MeasurementType } from '@graphql';
import { useIsMounted } from 'hooks';
import { UseFormRegisterReturn } from 'react-hook-form';
import { gql } from '@apollo/client';
import { groupBy } from 'lodash';

const GenderLabel = {
  MALE: "Men's",
  FEMALE: "Women's",
};

interface Props {
  register?: UseFormRegisterReturn;
  allowSelectAll?: boolean;
  onChange?: (value: string) => void;
  showLabel?: boolean;
  value?: string;
  measurementType?: MeasurementType;
  isDisabled?: boolean;
  isRequired?: boolean;
  filter?: 'garmentMeasurement';
}

// Note: a default value is not yet supported if this is used without handleOnChange instead of register
export const GarmentCategoriesSelect = ({
  register,
  allowSelectAll = false,
  onChange,
  showLabel = true,
  value,
  measurementType,
  isDisabled = false,
  isRequired = false,
}: //filter,
Props) => {
  const isMounted = useIsMounted();

  const { data, loading: isLoading } = useGarmentCategoriesSelectQuery({
    variables: { measurementType },
    fetchPolicy: 'cache-first',
  });

  const handleChange = (currentTarget: EventTarget & HTMLSelectElement) => {
    if (!isMounted || !onChange) {
      return;
    }
    onChange(currentTarget.value);
  };

  //const finishedMeasurementProducts = data?.settings?.finishedMeasurementProducts;

  const garmentCategories = data?.settings?.garmentCategoriesByMeasurementType;

  // if (measurementType) {
  //   garmentCategories = garmentCategories?.filter((garmentCategory) =>
  //     measurementType === MeasurementType.TryOn
  //       ? true
  //       : finishedMeasurementProducts.some((product) => (product as string) === (garmentCategory.key as string))
  //   );
  // }

  const categories = groupBy(garmentCategories, 'gender');

  return (
    <Select
      label={showLabel ? 'Garment category' : ''}
      register={register}
      htmlProps={{
        onChange: ({ currentTarget }) => handleChange(currentTarget),
        disabled: isDisabled || isLoading || !data?.settings?.garmentCategoriesByMeasurementType,
        ...(value && { value: value || '' }),
        required: isRequired,
      }}
    >
      {!isLoading && !data?.settings?.garmentCategoriesByMeasurementType && <option value="">Couldn&apos;t load categories</option>}
      <option value="">{allowSelectAll ? 'All categories' : 'Please select'}</option>
      {/* TODO: is there a better way to check which to hide (better than checking for the text "legacy") */}
      {Object.keys(categories).map(
        (gender) =>
          categories[gender].length && (
            <optgroup key={gender} label={GenderLabel[gender]}>
              {categories[gender].map(
                (product) =>
                  !product.name.includes('legacy') && (
                    <option key={product.key} value={product.key}>
                      {categories[gender]?.find((garmentCategory) => garmentCategory.key === product.key).truncatedName}
                    </option>
                  )
              )}
            </optgroup>
          )
      )}
    </Select>
  );
};

GarmentCategoriesSelect.fragments = {
  categories: gql`
    fragment GarmentCategoriesSelectFragment on GarmentCategorySetting {
      key
      name
      truncatedName
      hasLining
      hasGarmentMeasurement
      gender
    }
  `,
};

GarmentCategoriesSelect.query = gql`
  ${GarmentCategoriesSelect.fragments.categories}

  query GarmentCategoriesSelect($measurementType: MeasurementType) {
    settings {
      id
      garmentCategoriesByMeasurementType(measurementType: $measurementType) {
        ...GarmentCategoriesSelectFragment
      }
      finishedMeasurementProducts
    }
  }
`;
