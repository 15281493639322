import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import MultipleCombobox from './MultipleCombobox';
import { getStatusOptions, getSelectedOptions } from 'helpers/order-helpers';
import { filterUserRoles } from 'helpers/user-helpers';
import { OrderStatus, OrderFulfilmentStatus, AlterationStatus, InvoiceStatus, UserRole, UserStatus } from '@graphql';
import { useActiveSession } from 'hooks/useActiveSessionContext';

const OrderStatusMap: Record<OrderStatus, string> = {
  [OrderStatus.Archived]: 'Archived',
  [OrderStatus.Cancelled]: 'Cancelled',
  [OrderStatus.Complete]: 'Complete',
  [OrderStatus.Draft]: 'Draft',
  [OrderStatus.Error]: 'Error',
  [OrderStatus.Processing]: 'Processing',
  [OrderStatus.ReadyToProcess]: 'Ready to process',
};

const OrderFulfilmentStatusMap: Record<OrderFulfilmentStatus, string> = {
  [OrderFulfilmentStatus.Fulfilled]: 'Fulfilled',
  [OrderFulfilmentStatus.Unfulfilled]: 'Unfulfilled',
  [OrderFulfilmentStatus.PartiallyFulfilled]: 'Partially Fulfilled',
};

const UserRolesMap: Record<UserRole, string> = {
  [UserRole.SuperAdmin]: 'Super Admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Staff]: 'Staff',
};

const AlterationStatusMap: Record<AlterationStatus, string> = {
  [AlterationStatus.InProgress]: 'In Progress',
  [AlterationStatus.Cancelled]: 'Cancelled',
  [AlterationStatus.Completed]: 'Completed',
};

const UserStatusMap: Record<UserStatus, string> = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Inactive]: 'Inactive',
};

const InvoiceStatusMap: Record<InvoiceStatus, string> = {
  [InvoiceStatus.Open]: 'Open',
  [InvoiceStatus.PendingApproval]: 'Pending Approval',
  [InvoiceStatus.PaidInFull]: 'Paid',
};

export enum StatusParam {
  OrderStatus = 'orderStatus',
  FulfilmentStatus = 'orderFulfilmentStatus',
  AlterationStatus = 'alterationStatus',
  UserStatus = 'userStatus',
  UserRoles = 'userRoles',
  InvoiceStatus = 'invoiceStatus',
}

const statusMap = {
  [StatusParam.OrderStatus]: OrderStatusMap,
  [StatusParam.FulfilmentStatus]: OrderFulfilmentStatusMap,
  [StatusParam.AlterationStatus]: AlterationStatusMap,
  [StatusParam.UserStatus]: UserStatusMap,
  [StatusParam.UserRoles]: UserRolesMap,
  [StatusParam.InvoiceStatus]: InvoiceStatusMap,
};

const titleMap: Record<StatusParam, string> = {
  [StatusParam.OrderStatus]: 'Order Status',
  [StatusParam.FulfilmentStatus]: 'Fulfilment Status',
  [StatusParam.AlterationStatus]: 'Alteration Status',
  [StatusParam.UserStatus]: 'User Status',
  [StatusParam.UserRoles]: 'User Roles',
  [StatusParam.InvoiceStatus]: 'Invoice Status',
};

interface Props {
  filterParam: StatusParam;
}

const StatusCombobox = ({ filterParam }: Props) => {
  const { push, query } = useRouter();

  let options = useMemo(() => getStatusOptions(statusMap[filterParam]), []);
  const selected = useMemo(() => getSelectedOptions(options, query?.[filterParam]), [query]);

  const onChange = (options) => {
    push({ query: { ...query, [filterParam]: options.map((o) => o.id) } }, undefined, { shallow: true });
  };

  // Temperary method to filter dropdown options
  // TODO: move roleStatus to BE with current user's status as param to query back
  const { isSuperAdmin } = useActiveSession();

  if (filterParam === StatusParam.UserRoles) {
    options = filterUserRoles(options, isSuperAdmin);
  }

  return options.length ? (
    <MultipleCombobox options={options} selectedOptions={selected} placeholder={titleMap[filterParam]} onChange={onChange} />
  ) : null;
};

export default StatusCombobox;
