import { GetFabricIndividualPricingQuery } from '@graphql';
import { Reducer, useReducer } from 'react';
import { IndividualPricingState } from './types';
import { getInitialState } from './helpers';

const INITIAL_STATE: IndividualPricingState = {
  headers: [],
  initialRows: [],
  rows: [],
};

export const actions = {
  initialize: (data: GetFabricIndividualPricingQuery) => ({ type: 'INITIALIZE_STATE' as const, payload: { data } }),
  addFabric: (fabricCode: string, fabricLabel: string) => ({ type: 'ADD_FABRIC' as const, payload: { fabricCode, fabricLabel } }),
  updatePrice: (fabricIndex: number, productCategoryIndex: number, rawAmount: string) => ({
    type: 'UPDATE_PRICE' as const,
    payload: { fabricIndex, productCategoryIndex, rawAmount },
  }),
  switchPriceEditMode: (fabricIndex: number, productCategoryIndex: number) => ({
    type: 'SWITCH_PRICE_EDIT_MODE' as const,
    payload: { fabricIndex, productCategoryIndex },
  }),
};

type Actions = ReturnType<(typeof actions)[keyof typeof actions]>;

const reducer: Reducer<IndividualPricingState, Actions> = (prevState, { type, payload }) => {
  switch (type) {
    case 'INITIALIZE_STATE':
      return getInitialState(payload.data);
    case 'ADD_FABRIC': {
      const { rows, headers } = prevState;
      const { fabricCode, fabricLabel } = payload;
      return {
        ...prevState,
        rows: [
          ...rows,
          {
            fabricCode,
            label: fabricLabel,
            prices: headers.map(({ key, label }) => ({
              isEditMode: false,
              isUpdated: false,
              productCategory: key,
              rawAmount: '',
              productCategoryLabel: label,
            })),
          },
        ],
      };
    }
    case 'UPDATE_PRICE': {
      const { rows, initialRows } = prevState;
      const { fabricIndex, productCategoryIndex, rawAmount } = payload;

      const rowToUpdate = rows[fabricIndex];
      const priceToUpdate = rowToUpdate.prices[productCategoryIndex];

      const initialAmount = initialRows[fabricIndex]?.prices?.[productCategoryIndex]?.rawAmount ?? '';

      return {
        ...prevState,
        rows: [
          ...rows.slice(0, fabricIndex),
          {
            ...rowToUpdate,
            prices: [
              ...rowToUpdate.prices.slice(0, productCategoryIndex),
              {
                ...priceToUpdate,
                isUpdated: Number(initialAmount) !== Number(rawAmount),
                rawAmount,
              },
              ...rowToUpdate.prices.slice(productCategoryIndex + 1),
            ],
          },
          ...rows.slice(fabricIndex + 1),
        ],
      };
    }
    case 'SWITCH_PRICE_EDIT_MODE': {
      const { rows } = prevState;
      const { fabricIndex, productCategoryIndex } = payload;

      const rowToUpdate = rows[fabricIndex];
      const priceToUpdate = rowToUpdate.prices[productCategoryIndex];

      return {
        ...prevState,
        rows: [
          ...rows.slice(0, fabricIndex),
          {
            ...rowToUpdate,
            prices: [
              ...rowToUpdate.prices.slice(0, productCategoryIndex),
              {
                ...priceToUpdate,
                isEditMode: !priceToUpdate.isEditMode,
              },
              ...rowToUpdate.prices.slice(productCategoryIndex + 1),
            ],
          },
          ...rows.slice(fabricIndex + 1),
        ],
      };
    }
  }
};

export const useIndividualPricingReducer = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return {
    state,
    dispatch,
  };
};
